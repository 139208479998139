import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../constants/reducers/action";

const Faq = () => {
  const { faqObj } = useSelector((state) => state.collections);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!faqObj) {
      dispatch(getFaq());
    }
  }, []);
  return (
    <ul className="faq-list">
      {faqObj &&
        faqObj.length > 0 &&
        faqObj.map((val, index) => (
          <li>
            <a
              data-bs-toggle="collapse"
              className="collapsed"
              data-bs-target={`#faq${index}`}
            >
              {val?.Pag_Name}
              <i className="fa-solid fa-chevron-down icon-show"></i>
              <i className="fa-solid fa-xmark icon-close"></i>
            </a>
            <div
              id={`faq${index}`}
              className="collapse faq-ans"
              data-bs-parent=".faq-list"
            >
              <p>{val?.Pag_ShortDesc}</p>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default Faq;
