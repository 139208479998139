import React from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";
import Faq from "./home/Faq";
const Faqs = () => {
  return (
    <Layout>
      <Heading title={"FAQ's"} />

      <section className="faq-contact">
        <div className="camera-pic">
          <img
            src="assets/img/Camera_vector_img.png"
            alt=""
            className="img-fluid"
          />
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-xl-9">
              <div id="faq" className="faq">
                <Faq />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Faqs;
