import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BookYourTour from "../home/BookYourTour";
import { PATHS } from "../../constant";
import { useSelector } from "react-redux";

const Header = () => {
  const { response } = useSelector((state) => state.collections);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (response) {
      const modalElement = document.getElementById("bookNow");
      const closeButton = modalElement.querySelector(".btn-close");
      closeButton.click();
    }
  }, [response]);

  return (
    <>
      <header id="header" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
                <Link className="navbar-brand logo" to="/">
                  <img
                    src="/assets/img/uia-logo-b.svg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <div className="d-flex align-items-center">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse hLinks justify-content-center"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mb-2 mb-lg-0">
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === PATHS.SITE ? "active" : ""
                          }`}
                          to={PATHS.SITE}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === PATHS.ABOUTUS ? "active" : ""
                          }`}
                          to={PATHS.ABOUTUS}
                        >
                          About us
                        </Link>
                      </li>
                      {/* <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Tour Packages
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to={PATHS.DOMESTICTOUR}
                            >
                              Domestic Tours
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={PATHS.INTERNATIONALTOUR}
                            >
                              International Tours
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === PATHS.TOURDESTINATION
                              ? "active"
                              : ""
                          }`}
                          to={PATHS.TOURDESTINATION}
                        >
                          Tour Destinations
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === PATHS.BIKETOUR ? "active" : ""
                          }`}
                          to={PATHS.BIKETOUR}
                        >
                          Bike Tours
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === PATHS.GALLERY ? "active" : ""
                          }`}
                          to={PATHS.GALLERY}
                        >
                          Gallery
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === PATHS.CONTACTUS
                              ? "active"
                              : ""
                          }`}
                          to={PATHS.CONTACTUS}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <a
                    href="javascript:void(0)"
                    className="btn red-btn ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#bookNow"
                    onClick={handleShowModal}
                  >
                    <span>Book Now!</span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div
        className="modal fade"
        id="bookNow"
        tabIndex="-1"
        aria-labelledby="bookNowLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="front-booking-form bx-shadow-none mt-0 mb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>

                <BookYourTour />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
