/** @format */

import {
  RESPONSE,
  SLIDER,
  SERVICES,
  TESTIMONIALS,
  FAQ,
  GALLERY,
  BIKETOUR,
  TOUR_DETAILS,
  BLOGS,
  BLOG_DETAILS,
  BLOG_CATEGORY,
  POPULAR_TOUR,
} from "./types";

const INITIAL_STATE = {
  sliderOBj: [],
};

const collections = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESPONSE:
      return { ...state, response: action.payload };
    case SLIDER:
      return { ...state, sliderObj: action.payload };
    case SERVICES:
      return { ...state, servicesObj: action.payload };
    case TESTIMONIALS:
      return { ...state, testimonialObj: action.payload };
    case GALLERY:
      return { ...state, galleryObj: action.payload };
    case FAQ:
      return { ...state, faqObj: action.payload };
    case BIKETOUR:
      return { ...state, bikeTourObj: action.payload };
    case TOUR_DETAILS:
      return { ...state, tourDetailsObj: action.payload };
    case BLOGS:
      return { ...state, blogsObj: action.payload };
    case BLOG_DETAILS:
      return { ...state, blogDetailObj: action.payload };
    case BLOG_CATEGORY:
      return { ...state, blogCategoryObj: action.payload };
    case POPULAR_TOUR:
      return { ...state, popularObj: action.payload };
    default:
      return state;
  }
};
export default collections;
