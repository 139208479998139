import React, { useEffect, useState } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch, useSelector } from "react-redux";
import { getGallery } from "../constants/reducers/action";

const Gallery = () => {
  const { galleryObj } = useSelector((state) => state.collections);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGallery());
  }, []);

  let galleryImg = [];

  galleryObj &&
    galleryObj.map((vl) => {
      if (vl) {
        galleryImg.push(
          "https://admin.uniqueindiaadventures.com/uploads/" + vl.Gall_Image
        );
      }
    });

  const images = galleryImg;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const _view = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Layout>
      <Heading title={"Gallery"} />

      <section id="portfolio" className="portfolio">
        <div className="container">
          {/* <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">
                  All
                </li>
                <li data-filter=".filter-app">Kerala Tour</li>
                <li data-filter=".filter-card">Rajasthan Tour</li>
                <li data-filter=".filter-web">Ladakh Tour</li>
              </ul>
            </div>
          </div> */}

          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}

          <div
            className="row g-2 portfolio-container"
            data-aos-delay="200"
            key={galleryImg.length}
          >
            {galleryImg &&
              galleryImg.map((vl, index) => (
                <div
                  className="col-lg-3 col-md-4 portfolio-item filter-app"
                  onClick={() => _view(index)}
                  key={index}
                >
                  <div className="portfolio-wrap">
                    <img src={vl} className="img-fluid" alt="" />
                    <div className="portfolio-info">
                      <h4>View</h4>
                      <div className="portfolio-links"></div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Gallery;
