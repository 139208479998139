import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./component/home";
import About from "./component/about";
import Contact from "./component/contact";
import Gallery from "./component/gallery";
import BikeTour from "./component/BikeTour";
import TourDestination from "./component/TourDestination";
import DomesticTour from "./component/DomesticTour";
import InternationalTour from "./component/InternationalTour";
import TourDescription from "./component/TourDescription";
import Faqs from "./component/Faq";
import Testimonial from "./component/Testimonial";
import TermsCondition from "./component/TermsCondition";
import Blogs from "./component/Blogs";
import BlogDetails from "./component/BlogsDetails";

import { PATHS } from "./constant";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={PATHS.SITE} element={<Home />} />
        <Route path={PATHS.ABOUTUS} element={<About />} />
        <Route path={PATHS.CONTACTUS} element={<Contact />} />
        <Route path={PATHS.GALLERY} element={<Gallery />} />
        <Route path={PATHS.BIKETOUR} element={<BikeTour />} />
        <Route path={PATHS.TOURDESTINATION} element={<TourDestination />} />
        <Route path={PATHS.DOMESTICTOUR} element={<DomesticTour />} />
        <Route path={PATHS.INTERNATIONALTOUR} element={<InternationalTour />} />
        <Route path={PATHS.TOURDESCRIPTION} element={<TourDescription />} />
        <Route path={PATHS.FAQ} element={<Faqs />} />
        <Route path={PATHS.TESTIMONIAL} element={<Testimonial />} />
        <Route path={PATHS.TERMSCONDITION} element={<TermsCondition />} />
        <Route path={PATHS.BLOGS} element={<Blogs />} />
        <Route path={PATHS.BLOGDETAILS} element={<BlogDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
