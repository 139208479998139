import axios from "axios";

export const getUnauthedAxios = () =>
  axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getFormatDt = (dateObj) => {
  if (dateObj) {
    const date = new Date(dateObj);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);

    return formattedDate;
  }
};
