export const API_BASE_PATH = "https://admin.uniqueindiaadventures.com/api/";
export const API_PATHS = {
  SLIDER_API: `${API_BASE_PATH}getslider`,
  SERVICES_API: `${API_BASE_PATH}getService`,
  TESTIMONIAL_API: `${API_BASE_PATH}getTestimonial`,
  FAQ_API: `${API_BASE_PATH}getFaqs`,
  SUBMIT_ENQUIRY_API: `${API_BASE_PATH}submitInfo`,
  GALLERY_API: `${API_BASE_PATH}getGallery`,
  BIKETOUR_API: `${API_BASE_PATH}getTour`,
  TOURDETAILS_API: `${API_BASE_PATH}getTourDetails/`,
  BLOGS_API: `${API_BASE_PATH}getBlog`,
  BLOG_DETAIL_API: `${API_BASE_PATH}getBlogDetails/`,
  BLOG_CATEGORY_API: `${API_BASE_PATH}getBlogCategory`,
  POPULAR_API: `${API_BASE_PATH}getPopularTour`,
};
