import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { resetResponse } from "../../constants/reducers/action";
const Layout = ({ children }) => {
  const { response } = useSelector((state) => state.collections);
  const dispatch = useDispatch();
  useEffect(() => {
    if (response) {
      toast("We have received your enquiry, we will reach you asap");
      dispatch(resetResponse());
    }
  }, [response]);
  return (
    <>
      <ToastContainer />
      <Header />

      <main id="main">{children}</main>
      <Footer />
      <a
        href="under-maintenance.html"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="fa-solid fa-arrow-up"></i>
      </a>
    </>
  );
};

export default Layout;
