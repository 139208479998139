import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSlider } from "../../constants/reducers/action";

const Slider = () => {
  const dispatch = useDispatch();
  const { sliderObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!sliderObj) {
      dispatch(getSlider());
    }
  }, []);

  return (
    <>
      <section id="home" className="p-0">
        <div
          id="carouselExampleCaptions"
          className="home-banner carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {sliderObj &&
              sliderObj.length > 0 &&
              sliderObj.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to={index}
                  className={index === 0 ? "active" : ""}
                  aria-current={index === 0 ? "true" : undefined}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))}
          </div>
          <div className="carousel-inner">
            {sliderObj &&
              sliderObj.length > 0 &&
              sliderObj.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    src={item.image}
                    className="img-fluid d-block w-100"
                    alt={`Slide ${index + 1}`}
                  />
                  <div className="carousel-caption">
                    <div className="slider-content">
                      <span>{item.subtitle}</span>
                      <h2>{item.title}</h2>
                      <Link to={item.link} className="red-btn btn px-4">
                        Let's Go!
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="pattern-layer"></div>
      </section>
    </>
  );
};

export default Slider;
