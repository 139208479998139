import React, { Component, useEffect } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getBikeTour } from "../constants/reducers/action";
import { Link } from "react-router-dom";
import { PATHS } from "../constant";

function BikeTour() {
  const dispatch = useDispatch();
  const { bikeTourObj } = useSelector((state) => state.collections);
  useEffect(() => {
    if (!bikeTourObj) {
      dispatch(getBikeTour());
    }
  }, [bikeTourObj]);

  console.log(bikeTourObj);
  const colClasses = [
    "col-md-5 col-12",
    "col-md-7 col-12",
    "col-md-4 col-12",
    "col-md-6 col-12",
  ];
  function getRandomColClass() {
    const randomIndex = Math.floor(Math.random() * colClasses.length);
    return colClasses[randomIndex];
  }
  return (
    <Layout>
      <Heading title={"Bike Tours"} />

      <section>
        <div className="container">
          <div className="row justify-content-center">
            {bikeTourObj &&
              bikeTourObj.map((vl, index) => {
                const colClass = getRandomColClass();
                return (
                  <div className={`col-md-4 col-12`} key={index}>
                    <div className="destination-item item">
                      <Link
                        to={PATHS.TOURDESCRIPTION.replace(":id", vl?.Pag_Id)}
                      >
                        <img
                          src={
                            "https://admin.uniqueindiaadventures.com/uploads/" +
                            vl?.Pag_Image
                          }
                          alt=""
                          className="img-fluid"
                        />
                        <div className="info-overlay">
                          <h3>{vl?.Pag_URL}</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BikeTour;
