import React, { useEffect } from "react";

import Slider from "./Slider";
import Layout from "../layout";
import WhatWeServe from "./WhatWeServe";
import PopularDestination from "./PopularDestionation";
import OurServices from "./OurServices";
import HappyMoment from "./HappyMoment";
import Testimonial from "./Testimonial";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../constants/reducers/action";
import Faq from "./Faq";

function Home() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    console.log(formData);
  };

  return (
    <Layout>
      <>
        <Slider />
        <WhatWeServe />
        <PopularDestination />
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-6 d-flex align-items-center">
                <div className="content pe-4">
                  <div className="title-header">
                    <span>Discover the world, one adventure at a time.</span>
                    <h2 className="title mb-2">
                      Explore the colors and cultures <br /> of India with us.
                    </h2>
                  </div>

                  <p className="fst-italic short-p">
                    Welcome to our tour and travel website, where we believe
                    that traveling is more than just visiting new places. It's
                    about creating unforgettable experiences, discovering new
                    cultures, and making memories that last a lifetime.
                  </p>
                  <p className="fst-italic short-p mb-3">
                    We offer a range of personalized travel packages, designed
                    to cater to every type of traveler. Whether you're seeking
                    adventure, relaxation, or a mix of both, our team of
                    experienced professionals is dedicated to helping you plan
                    your dream trip.
                  </p>
                  <h5>Let's start your journey today!</h5>

                  <hr />
                  <div className="featured-icon-box">
                    <div className="ind-map">
                      <img
                        src="assets/img/india-map.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h6>
                          We Have
                          <span className="text-base-skin">10+ Years</span>
                          Of Experience For Give You Better Tour &amp; Travels
                          Package
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-6">
                <div className="image">
                  <img
                    src="assets/img/about.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <OurServices />
        <HappyMoment />
        <Testimonial />
        <section className="faq-contact">
          <div className="camera-pic">
            <img
              src="assets/img/Camera_vector_img.png"
              alt=""
              className="img-fluid"
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-7">
                <div id="faq" className="faq">
                  <div className="title-header">
                    <span>FAQ</span>
                    <h2 className="title">Frequently Asked Questions</h2>
                  </div>
                  <Faq />
                </div>
              </div>
              <div className="col-lg-4 col-xl-5">
                <div id="contact" className="contact">
                  <div className="title-header">
                    <span>Get in touch!</span>
                    <h2 className="title">
                      Need assistance or have any inquiries?
                    </h2>
                  </div>
                  <div className="contact-form section-bg">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      method="post"
                      role="form"
                      className="php-email-form"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            Fill out the form below, and our dedicated team will
                            get back to you promptly to provide the support you
                            need.
                          </p>
                        </div>
                        <div className="col-lg-12 col-xl-6 form-group">
                          <input
                            type="text"
                            {...register("name", { required: true })}
                            className="form-control"
                            id="name"
                            placeholder="Your Name"
                          />
                          {errors.name && (
                            <span className="text-danger pt-0 mt-0">
                              This field is required
                            </span>
                          )}
                        </div>
                        <div className="col-lg-12 col-xl-6 form-group">
                          <input
                            type="email"
                            className="form-control"
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Please enter a valid email address",
                              },
                            })}
                            id="email"
                            placeholder="Your Email"
                          />
                          {errors.email && (
                            <span className="text-danger">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          {...register("subject", { required: true })}
                          id="subject"
                          placeholder="Subject"
                        />
                        {errors.subject && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="5"
                          placeholder="Message"
                        />
                      </div>
                      <div className="">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit">Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
}

export default Home;
