import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel2";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonial } from "../../constants/reducers/action";

const options = {
  items: 3,
  loop: true,
  center: true,
  nav: true,
  autoplay: true,
};

const Testimonial = () => {
  const dispatch = useDispatch();
  const { testimonialObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!testimonialObj) {
      dispatch(getTestimonial());
    }
  }, []);

  return (
    <section className="testimonial">
      <div className="pattern-layer-1"></div>
      <div className="container pt-5">
        <div className="section-title-w">
          <span>Testimonials</span>
          <h2>What our client's says</h2>
        </div>
        <div className="row g-2">
          <div className="col-md-12">
            <OwlCarousel options={options}>
              {testimonialObj &&
                testimonialObj.length > 0 &&
                testimonialObj.map((vl, index) => (
                  <div className="item" key={index}>
                    <div className="content">
                      <div className="d-flex mb-3 justify-content-between align-items-end">
                        <div className="quotes-1">
                          <img
                            src="assets/img/quotes-1.svg"
                            alt="Testimonial"
                          />
                        </div>
                        <div className="thumb">
                          <img src={vl?.image} alt="Testimonial" />
                        </div>
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: vl.description }} />

                      <div className="author-rating">
                        <div className="author-info">
                          <div className="info">
                            <h6 className="title">{vl?.name}</h6>
                            <span className="subtitle">{vl?.subtitle}</span>
                          </div>
                        </div>
                        <div className="rating-icon">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
