/** @format */

import {
  SLIDER,
  SERVICES,
  TESTIMONIALS,
  FAQ,
  RESPONSE,
  GALLERY,
  BIKETOUR,
  TOUR_DETAILS,
  BLOGS,
  BLOG_DETAILS,
  BLOG_CATEGORY,
  POPULAR_TOUR,
} from "./types";
import { API_PATHS } from "./api-route";
import { getUnauthedAxios } from "../../helper";

export const getSlider = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.SLIDER_API);
    dispatch({ type: SLIDER, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getServices = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.SERVICES_API);
    dispatch({ type: SERVICES, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getTestimonial = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.TESTIMONIAL_API);
    dispatch({ type: TESTIMONIALS, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getFaq = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.FAQ_API);
    dispatch({ type: FAQ, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getGallery = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.GALLERY_API);
    dispatch({ type: GALLERY, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getBikeTour = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.BIKETOUR_API);
    dispatch({ type: BIKETOUR, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getTourDetails = (id) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.TOURDETAILS_API + id);
    dispatch({ type: TOUR_DETAILS, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const submitEnquiry = (data) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.post(API_PATHS.SUBMIT_ENQUIRY_API, data);
    dispatch({ type: RESPONSE, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const resetResponse = () => async (dispatch) => {
  dispatch({ type: RESPONSE, payload: undefined });
};

export const getBlogs = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.BLOGS_API);
    dispatch({ type: BLOGS, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getBlogDetail = (id) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.BLOG_DETAIL_API + id);
    dispatch({ type: BLOG_DETAILS, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getBlogCategory = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.BLOG_CATEGORY_API);
    dispatch({ type: BLOG_CATEGORY, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};

export const getPopularTour = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const res = await instance.get(API_PATHS.POPULAR_API);
    dispatch({ type: POPULAR_TOUR, payload: res.data });
  } catch (error) {
    console.log(false);
  }
};
