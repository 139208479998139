import React, { useEffect } from "react";

import Layout from "./layout";
import Heading from "./layout/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonial } from "../constants/reducers/action";

const Testimonial = () => {
  const dispatch = useDispatch();
  const { testimonialObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!testimonialObj) {
      dispatch(getTestimonial());
    }
  }, []);
  return (
    <Layout>
      <Heading title={"What Others Say About Us"} />

      <section className="section-primary t-bordered">
        <div className="container">
          <div className="row testimonial-three testimonial-three--col-three">
            {testimonialObj &&
              testimonialObj.map((vl, index) => (
                <div
                  className="col-md-6 col-lg-4 testimonial-three-col"
                  key={index}
                >
                  <div className="testimonial-inner">
                    <div className="testimonial-image" itemprop="image">
                      <img width="180" height="180" src={vl?.image} />
                    </div>
                    <div className="testimonial-content">
                      <p dangerouslySetInnerHTML={{ __html: vl.description }} />
                    </div>
                    <div className="testimonial-meta">
                      <strong className="testimonial-name" itemprop="name">
                        {vl?.name}
                      </strong>
                      <span
                        className="testimonial-job-title"
                        itemprop="jobTitle"
                      >
                        {vl?.subtitle}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Testimonial;
