import React, { Component } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";

function DomesticTour() {
  return (
    <Layout>
      <Heading title={"Domestic Tours"} />

      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/biketours/bt-himalaya.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>Leh Ladakh Himalayans</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/biketours/south-india-bt.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>South India</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="destination-item item">
                <a href="rajasthan-bike-tour.html">
                  <img
                    src="assets/img/biketours/rajasthan-tour.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>Rajasthan</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/biketours/north-india-bt.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>North India</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/biketours/east-india-bt.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>East India</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="destination-item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/biketours/gujarat-bt.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>Gujarat</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="destination-item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/biketours/nepal-bt.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    <h3>Nepal</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default DomesticTour;
