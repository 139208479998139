import React, { useEffect } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../constants/reducers/action";
import { getFormatDt } from "../helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../constant";
import BlogCategory from "./blogCategory";
const Blogs = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const { blogsObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!blogsObj) {
      dispatch(getBlogs());
    }
  }, [blogsObj]);

  const _redirect = (obj) => {
    navigate(PATHS.BLOGDETAILS.replace(":id", obj?.id));
  };
  console.log(location?.state);
  return (
    <Layout>
      <Heading title={"Blogs"} />

      <section class="blog">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="row posts-list">
                {blogsObj &&
                  blogsObj.length > 0 &&
                  blogsObj.map((vl, index) => {
                    if (location?.state === vl?.category.id || !location?.state)
                      return (
                        <div
                          class="col-xl-4 col-lg-6 col-md-6 mb-4 pointer"
                          key={index}
                        >
                          <article class="d-flex flex-column">
                            <div class="post-img">
                              <img src={vl?.image} alt="" class="img-fluid" />
                            </div>
                            <div class="content">
                              <div>
                                <h2 class="title">
                                  <a href="#" onClick={() => _redirect(vl)}>
                                    {vl?.title}
                                  </a>
                                </h2>
                                <div class="meta-top">
                                  <ul>
                                    <li class="d-flex align-items-center">
                                      <i class="fa-solid fa-user"></i>{" "}
                                      <a href="#" onClick={() => _redirect(vl)}>
                                        UIA
                                      </a>
                                    </li>
                                    <li class="d-flex align-items-center">
                                      <i class="fa-solid fa-clock"></i>{" "}
                                      <a href="#" onClick={() => _redirect(vl)}>
                                        <time datetime="2022-01-01">
                                          {getFormatDt(vl?.date)}
                                        </time>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: vl?.fullDesc.substring(0, 200),
                                  }}
                                />
                              </div>

                              <div class="read-more pointer">
                                <span onClick={() => _redirect(vl)}>
                                  Read More{" "}
                                  <i class="fa-solid fa-arrow-right-long"></i>
                                </span>
                              </div>
                            </div>
                          </article>
                        </div>
                      );
                  })}
              </div>
              {/* <div class="blog-pagination">
                <ul class="justify-content-center">
                  <li>
                    <a href="#">1</a>
                  </li>
                  <li class="active">
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                </ul>
              </div> */}
            </div>
            <BlogCategory />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Blogs;
