import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitEnquiry } from "../../constants/reducers/action";

const BookYourTour = () => {
  const dispatch = useDispatch();
  const { response } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    dispatch(submitEnquiry(data));
  };
  const [travellers, setTravellers] = useState(1);
  const [days, setDays] = useState(1);

  const handleIncrement = () => {
    setTravellers((prevTravellers) => prevTravellers + 1);
  };

  const handleDecrement = () => {
    if (travellers > 1) {
      setTravellers((prevTravellers) => prevTravellers - 1);
    }
  };
  const handleIncrementDays = () => {
    setDays((prevDays) => prevDays + 1);
  };

  const handleDecrementDays = () => {
    if (days > 1) {
      setDays((prevDays) => prevDays - 1);
    }
  };
  useEffect(() => {
    if (response) {
      reset();
    }
  }, [response]);
  return (
    <div className="front-booking-form p-3 m-0">
      <h2>Book Your Tour</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row g-3">
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                id=""
                placeholder="Name"
                {...register("Con_Name", { required: true })}
              />
              {errors.Con_Name && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id=""
                placeholder="Email"
                {...register("Con_Email", {
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                })}
              />
              {errors.Con_Email && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                Phone Number:
              </label>
              <input
                type="number"
                className="form-control"
                id=""
                placeholder="Phone Number"
                {...register("Con_Number", {
                  required: "Phone Number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Invalid phone number",
                  },
                })}
              />
              {errors.Con_Number && (
                <span className="text-danger">{errors.Con_Number.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                Country:
              </label>
              {/* <select
                className="form-select"
                aria-label="Default select example"
                {...register("Con_Country", {
                  required: "Country is required",
                })}
              >
                <option selected value="">
                  Select here...
                </option>
                <option value="1">Country 1</option>
                <option value="2">Country 2</option>
                <option value="3">Country 3</option>
              </select> */}

              <select
                className="form-select"
                aria-label="Default select example"
                {...register("Con_Country", {
                  required: "Country is required",
                })}
              >
                <option value="" selected>
                  Select here...
                </option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Eswatini">Eswatini</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Greece">Greece</option>
                <option value="Grenada">Grenada</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Honduras">Honduras</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="North Korea">North Korea</option>
                <option value="South Korea">South Korea</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia">Micronesia</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="North Macedonia">North Macedonia</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Qatar">Qatar</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Vincent and the Grenadines">
                  Saint Vincent and the Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-Leste">Timor-Leste</option>
                <option value="Togo">Togo</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City">Vatican City</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </div>
            {errors.Con_Country && (
              <span className="text-danger">{errors.Con_Country.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                No. of Travellers:
              </label>
              <div className="input-group">
                <button
                  className="btn btn-dark"
                  type="button"
                  id="button-addon1"
                  onClick={handleDecrement}
                >
                  -
                </button>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number"
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  value={travellers}
                  {...register("Con_Travellers", {
                    required: "Number of Travellers is required",
                    min: { value: 1, message: "Must be at least 1 traveller" },
                  })}
                />

                <button
                  className="btn btn-dark"
                  type="button"
                  id="button-addon1"
                  onClick={handleIncrement}
                >
                  +
                </button>
              </div>
              {errors.travellers && (
                <span className="text-danger">{errors.travellers.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-label">
              No. of Days:
            </label>
            <div className="input-group">
              <button
                className="btn btn-dark"
                type="button"
                id="button-addon1"
                onClick={handleDecrementDays}
              >
                -
              </button>
              <input
                type="text"
                className="form-control"
                placeholder="Number"
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                value={days}
                readOnly
                {...register("Con_Days", {
                  required: "Number of Days is required",
                  min: { value: 1, message: "Must be at least 1 day" },
                })}
              />
              <button
                className="btn btn-dark"
                type="button"
                id="button-addon1"
                onClick={handleIncrementDays}
              >
                +
              </button>
            </div>
            {errors.days && (
              <span className="text-danger">{errors.days.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                Hotels:
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("Con_Hotels", { required: "Hotel is required" })}
              >
                <option value="" selected>
                  Select Hotel Category...
                </option>
                <option value="Deluxe">Deluxe</option>
                <option value="Semi Deluxe">Semi Deluxe</option>
                <option value="AC">AC</option>
                <option value="Non AC">Non AC</option>
                <option value="1 Star">1 Star</option>
                <option value="2 Star">2 Star</option>
                <option value="3 Star">3 Star</option>
                <option value="4 Star">4 Star</option>
                <option value="5 Star">5 Star</option>
                <option value="Luxury">Luxury</option>
                <option value="Boutique">Boutique</option>
                <option value="Budget">Budget</option>
              </select>
            </div>
            {errors.Con_Hotels && (
              <span className="text-danger">{errors.Con_Hotels.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <div>
              <label htmlFor="" className="form-label">
                Car:
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("Con_Car", {
                  required: "Vehicle type is required",
                })}
              >
                <option value="" selected>
                  Select Vehicle Type...
                </option>
                <option value="Bike">Bike</option>
                <option value="TT Traveller">TT Traveller</option>
                <option value="Sedan">Sedan</option>
                <option value="SUV">SUV</option>
                <option value="Hatchback">Hatchback</option>
                <option value="Luxury Car">Luxury Car</option>
                <option value="Van">Van</option>
                <option value="Mini Bus">Mini Bus</option>
                <option value="Coach">Coach</option>
              </select>
            </div>
            {errors.Con_Car && (
              <span className="text-danger">{errors.Con_Car.message}</span>
            )}
          </div>
          <div className="col-md-12 mb-1">
            <div>
              <label htmlFor="" className="form-label">
                Brief Summary / Special Requirements:
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ height: "100px" }}
                {...register("Con_Desc", {
                  required: "Brief summary is required",
                })}
              ></textarea>
            </div>
            {errors.brief && (
              <span className="text-danger">{errors.brief.message}</span>
            )}
          </div>
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <button type="submit" className="btn red-btn w-100">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BookYourTour;
