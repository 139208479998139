import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPopularTour } from "../../constants/reducers/action";
import { Link } from "react-router-dom";
import { PATHS } from "../../constant";

const PopularDestination = () => {
  const dispatch = useDispatch();
  const { popularObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!popularObj) {
      dispatch(getPopularTour());
    }
  }, [popularObj]);

  return (
    <section className="popular-tour">
      <div className="pattern-layer-1"></div>

      <div className="container pt-5">
        <div className="section-title-w">
          <span>Modern & Beautiful</span>
          <h2>Popular Destinations</h2>
        </div>
        <div className="row">
          {popularObj &&
            popularObj.map((vl, index) => (
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                key={index}
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src={
                        "https://admin.uniqueindiaadventures.com/uploads/" +
                        vl?.Pag_Image
                      }
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <Link
                        to={PATHS.TOURDESCRIPTION.replace(":id", vl?.Pag_Id)}
                        className="red-btn btn"
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>{vl?.Pag_URL}</h4>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PopularDestination;
