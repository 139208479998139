import React from "react";

import Layout from "./layout";
import Heading from "./layout/Heading";

const TermsCondition = () => {
  return (
    <Layout>
      <Heading title={"Terms & Conditions"} />
      <section className="faq-contact">
        <div className="camera-pic">
          <img
            src="assets/img/Camera_vector_img.png"
            alt=""
            className="img-fluid"
          />
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* <p>
                Terms and Conditions form an integral part of the tour contract
                entered into between the customer and Bike Tour Asia Ltd.
                (herein called BTA). Any special conditions and itineraries
                detailed in the various travel offers between the customer and
                BTA shall prevail these Terms and Conditions. All statutory
                references herein shall refer to the said statutes under Thai
                law.
              </p> */}
              <br />
              {/* <h3>I. General travel terms and conditions</h3>
              <p>
                Save as otherwise stipulated herein the provisions of the Civil
                and Commercial Code and the Tourism Business and Tour Guides Act
                2008 shall apply.
                <br />
                The purpose of these Terms and Conditions:
              </p>
              <p>
                Tour operator is a licensed tour operator under the Tourism
                Business and Tour Guides Act 2008 either offering several
                touristic services at a package price (package holiday/travel
                organization) or promising to render individual touristic
                services as services on own account and for this purpose usually
                providing own brochures, advertisements, etc. If third party
                services are arranged (e.g. optional trips at the holiday
                resort), a company acting as tour operator may also act as agent
                if it refers to this function as agent. Bike Tour Asia as Tour
                Operator
              </p> */}
              {/* <h4>1. Booking/contract effectiveness</h4>
              <p>
                The following provisions form the basis of the contract –
                hereinafter referred to as tour contract – concluded between the
                booking party and BTA as tour operator either directly or
                through an agent. In case of an indirect contract, the agent’s
                obligations analogously apply to the tour operator.
              </p>
              <p>
                The booking can be effected in writing, via on-line, per
                telephone or verbally. BTA should then immediately confirm in
                writing the booking if known contact of the customer is given to
                BTA.
              </p>
              <p>
                The tour contract is effective as between the booking party and
                BTA if the parties agree on the material parts of the contract
                (price, service and date). This results in rights and duties for
                the customer and BTA.
              </p> */}
              <h4>1. Booking and Reservations</h4>
              <dir>
                <h6>
                  1.1. All bookings and reservations are subject to
                  availability.
                </h6>
                <h6>
                  1.2. A deposit or full payment may be required to confirm a
                  booking.
                </h6>
                <h6>
                  1.3. Booking confirmation is subject to receipt of payment and
                  availability of the tour.
                </h6>
                <br />
              </dir>

              <h4>2. Cancellation and Refunds</h4>
              <dir>
                <h6>
                  2.1. Cancellation policies vary depending on the tour package
                  and provider..
                </h6>
                <h6>
                  2.2. Cancellations made within a specified period may incur a
                  cancellation fee.
                </h6>
                <h6>
                  2.3. Refunds, if applicable, will be processed according to
                  the cancellation policy.
                </h6>
                <br />
              </dir>

              <h4>3. Tour Packages</h4>
              <dir>
                <h6>
                  3.1. Tour itineraries are subject to change based on weather
                  conditions, road closures, or unforeseen circumstances.
                </h6>
                <h6>
                  3.2. Unique India Adventures reserves the right to modify tour
                  packages for the safety and enjoyment of participants.
                </h6>

                <br />
              </dir>

              <h4>4. Participant Requirements</h4>
              <dir>
                <h6>
                  4.1. Participants must meet the physical requirements
                  specified for each tour.
                </h6>
                <h6>
                  4.2. Participants are responsible for their own health and
                  safety during the tour.
                </h6>

                <h6>
                  4.3. Any medical conditions or dietary requirements must be
                  disclosed at the time of booking.
                </h6>

                <br />
              </dir>

              <h4>5. Insurance</h4>
              <dir>
                <h6>
                  5.1. Participants are advised to have adequate travel
                  insurance covering medical expenses, trip cancellations, and
                  personal liability.
                </h6>
                <h6>
                  5.2. Unique India Adventures is not liable for any loss,
                  injury, or damage incurred during the tour.
                </h6>

                <br />
              </dir>

              <h4>6. Liability</h4>
              <dir>
                <h6>
                  6.1. Unique India Adventures is not liable for any loss,
                  injury, or damage to participants or their belongings during
                  the tour.
                </h6>
                <h6>
                  6.2. Participants engage in tours at their own risk and are
                  responsible for their own safety.
                </h6>

                <br />
              </dir>

              <h4>7. Payment</h4>
              <dir>
                <h6>
                  7.1. Payment methods accepted include credit/debit cards, bank
                  transfers, and online payment platforms.
                </h6>
                <h6>
                  7.2. Full payment is required before the tour departure date
                  unless otherwise specified.
                </h6>

                <br />
              </dir>

              <h4>8. Changes and Cancellations by Unique India Adventures</h4>
              <dir>
                <h6>
                  8.1. Unique India Adventures reserves the right to cancel or
                  reschedule tours due to unforeseen circumstances.
                </h6>
                <h6>
                  8.2. In the event of a cancellation by Unique India
                  Adventures, participants will be offered an alternative tour
                  or a full refund.
                </h6>

                <br />
              </dir>

              <h4>9. Code of Conduct</h4>
              <dir>
                <h6>
                  9.1. Participants are expected to follow the instructions of
                  the tour guides and adhere to local laws and customs.
                </h6>
                <h6>
                  9.2. Disruptive behavior or non-compliance with safety
                  guidelines may result in removal from the tour without a
                  refund.
                </h6>

                <br />
              </dir>

              <h4>10. Agreement</h4>
              <dir>
                <h6>
                  10.1. By booking a tour with Unique India Adventures,
                  participants agree to abide by these terms and conditions.
                </h6>

                <br />
              </dir>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsCondition;
