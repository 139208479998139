import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../constant";

const Footer = () => {
  const _blog = () => {
    window.location.assign(PATHS.BLOGS);
  };
  return (
    <>
      <footer id="footer">
        <div className="pattern-layer-1"></div>
        <div className="footer-top">
          <div className="container pt-4">
            <div className="row">
              <div className="col-xl-3 col-lg-3 footer-contact d-none d-xl-block">
                <div className="footer-logo mb-2 mb-xxl-3">
                  <img
                    src="/assets/img/uia-white-logo.svg"
                    alt="Logo"
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="col-xl-6 col-lg-8">
                <div className="row">
                  <div className="col-md-3 footer-links">
                    <h4>Site Map</h4>
                    <ul>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <Link to={PATHS.SITE}>Home </Link>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <Link to={PATHS.ABOUTUS}>About Us</Link>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={PATHS.BIKETOUR}>Bike Tours </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={PATHS.GALLERY}>Gallery </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-5 footer-links">
                    <h4>Top Destinations</h4>
                    <ul>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href="under-maintenance.html">Himalayas </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href="under-maintenance.html">South India </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href="under-maintenance.html">
                          Kerala Motorcycle Tour{" "}
                        </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href="under-maintenance.html">
                          Rajasthan Motorcycle Tour{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 footer-links">
                    <h4>Bike Tour</h4>
                    <ul>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={PATHS.FAQ}>FAQ </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={PATHS.CONTACTUS}>Contact Us </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={PATHS.TESTIMONIAL}>Testimonials</a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={PATHS.TERMSCONDITION}>Terms & Conditions </a>
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                        <a href={`#`} onClick={() => _blog()}>
                          Blogs{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 footer-contact">
                <h4>Get In Touch!</h4>
                <p className="ps-3 mb-1 mb-xxl-2">
                  <strong>Office:</strong> <br />
                  P.No. 229, Nehru Nagar, <br />
                  Delhi Road, Kunda Amer <br />
                  Rajasthan, India <br />
                  <br />
                  <strong>Phone:</strong> +91-9829324887
                  <br />
                  <strong>Email:</strong> info@uniqueindiaadventures.com
                  <br />
                </p>
                <div className="social-links ps-3">
                  <a href="under-maintenance.html" className="twitter">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a href="under-maintenance.html" className="facebook">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                  <a href="under-maintenance.html" className="instagram">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a href="under-maintenance.html" className="linkedin">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          &copy; Copyright 2022{" "}
          <strong>
            <span>Unique India Adventures</span>
          </strong>
          . All Rights Reserved
        </div>
      </footer>
    </>
  );
};

export default Footer;
