import React from "react";

const Heading = ({ title }) => {
  return (
    <section
      className="inner-banner p-0"
      style={{
        background: "url(/assets/img/2.png) center center / cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="ip-heading">
              <span>{title}</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="pattern-layer"></div>
    </section>
  );
};

export default Heading;
