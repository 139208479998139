import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGallery } from "../../constants/reducers/action";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const HappyMoment = () => {
  const { galleryObj } = useSelector((state) => state.collections);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGallery());
  }, []);

  // Extract image URLs from galleryObj and limit to 8 images
  const galleryImg = galleryObj
    ? galleryObj
        .slice(0, 8)
        .map(
          (vl) =>
            `https://admin.uniqueindiaadventures.com/uploads/${vl.Gall_Image}`
        )
    : [];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const _view = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <section id="portfolio" className="portfolio" key={Math.random()}>
      <div className="container">
        <div className="section-title pb-4">
          <span>Happy Moments</span>
          <h2>Our Tour Gallery</h2>
        </div>
        <div className="row g-2 portfolio-container" data-aos-delay="200">
          {isOpen && (
            <Lightbox
              mainSrc={galleryImg[photoIndex]}
              nextSrc={galleryImg[(photoIndex + 1) % galleryImg.length]}
              prevSrc={
                galleryImg[
                  (photoIndex + galleryImg.length - 1) % galleryImg.length
                ]
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + galleryImg.length - 1) % galleryImg.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % galleryImg.length)
              }
            />
          )}

          {galleryImg.map((vl, index) => (
            <div
              className="col-lg-3 col-md-4 portfolio-item filter-app"
              onClick={() => _view(index)}
              key={index}
            >
              <div className="portfolio-wrap">
                <img src={vl} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>View</h4>
                  <div className="portfolio-links"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HappyMoment;
