import React, { useEffect } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getBikeTour, getTourDetails } from "../constants/reducers/action";
import { useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
const options = {
  items: 4,
  loop: true,
  center: true,
  nav: false,
  autoplay: true,
};

const TourDescription = () => {
  const { tourDetailsObj, bikeTourObj } = useSelector(
    (state) => state.collections
  );
  const dispatch = useDispatch();
  const parms = useParams();

  useEffect(() => {
    dispatch(getTourDetails(parms.id));
  }, [parms.id]);

  useEffect(() => {
    if (!bikeTourObj) {
      dispatch(getBikeTour());
    }
  }, [bikeTourObj]);

  return (
    <Layout>
      <Heading title={tourDetailsObj?.Pag_URL} />
      <section className="pt-5">
        <div className="container">
          <div className="row g-3">
            <div className="col-md-8 col-lg-8 col-xl-9">
              <div className="pg-banner mb-4">
                <div className="mb-4">
                  <div className="title-header">
                    <span>Padharo Mhare Des!</span>
                    <h2 className="title">{tourDetailsObj?.Pag_URL}</h2>
                  </div>
                  <h3 className="fw-bold"></h3>
                  <div className="tour-reviews mb-2">
                    {/* <div>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </div> */}
                    {/* <span className="badge bg-warning fs-6">4</span> */}
                    {/* <span>46 reviews</span> */}
                  </div>
                  <div>
                    <span className="me-2">
                      <i className="fa-regular fa-clock"></i>{" "}
                      {tourDetailsObj?.Pag_Date}
                    </span>
                    <span>
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      {tourDetailsObj?.Pag_Name}
                    </span>
                  </div>
                </div>
                <img
                  src={
                    "https://admin.uniqueindiaadventures.com/uploads/" +
                    tourDetailsObj?.Pag_Image
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              {console.log(tourDetailsObj)}

              <div>
                <div className="title-header">
                  <h2 className="title">{tourDetailsObj?.Pag_URL}</h2>
                </div>

                <p
                  dangerouslySetInnerHTML={{
                    __html: tourDetailsObj?.Pag_ShortDesc,
                  }}
                />
              </div>
              <hr className="my-4" />
              <div>
                <div className="title-header">
                  <h2 className="title">Highlights</h2>
                </div>

                <span
                  className="days-list"
                  dangerouslySetInnerHTML={{
                    __html: tourDetailsObj?.Pag_FullDesc,
                  }}
                />
                {/* <ul className="days-list">
                  <li>
                    <i className="fa-regular fa-circle-check"></i>{" "}
                    <span>
                      Witness the grandeur of the land of valor, traditions and
                      culture.
                    </span>
                  </li>
                  <li>
                    <i className="fa-regular fa-circle-check"></i>{" "}
                    <span>
                      Get acquainted with the challenging routes passing through
                      forests, foothills and villages.
                    </span>
                  </li>
                  <li>
                    <i className="fa-regular fa-circle-check"></i>{" "}
                    <span>
                      Visit the Longewala post, the Indo-Pak border of the
                      western region.
                    </span>
                  </li>
                  <li>
                    <i className="fa-regular fa-circle-check"></i>{" "}
                    <span>
                      The Golden City of Jaisalmer, the Blue City, the Pink City
                      and the City of Lakes.
                    </span>
                  </li>
                  <li>
                    <i className="fa-regular fa-circle-check"></i>{" "}
                    <span>
                      Get a glimpse of the Holy and Bohemian vibes of Pushkar.
                    </span>
                  </li>
                  <li>
                    <i className="fa-regular fa-circle-check"></i>{" "}
                    <span>
                      Visit India’s one of the oldest and largest forts engraved
                      with the tales of bravery.
                    </span>
                  </li>
                </ul> */}
              </div>
              <hr className="my-4" />

              <div className="tour-timeline mb-4">
                <div className="title-header">
                  <h2 className="title">
                    Itinerary For {tourDetailsObj?.Pag_URL}
                  </h2>
                </div>

                <ul className="timeline timeline-left">
                  {tourDetailsObj?.itineraries.length > 0 &&
                    tourDetailsObj?.itineraries.map((vl, index) => (
                      <li
                        className="timeline-inverted timeline-item"
                        key={index}
                      >
                        <div className="timeline-badge warning">
                          {index + 1}
                        </div>
                        <div className="timeline-panel">
                          <div className="timeline-heading">
                            <h4 className="timeline-title">{vl?.Iti_Title}</h4>
                            {/* <p>
                              <small className="text-muted"> New Delhi</small>
                            </p> */}
                          </div>
                          <div className="timeline-body">
                            <p
                              className="mb-2 fw-bold"
                              dangerouslySetInnerHTML={{ __html: vl?.Iti_Desc }}
                            />
                            {/* <p className="mb-2 fw-bold">
                              Ready to explore Mandawa Villege
                            </p>
                            <ul className="days-list">
                              <li>
                                <i className="fa-regular fa-circle-check"></i>{" "}
                                <span>
                                  Your Rajasthan bike trip will start in the
                                  morning. You will be beginning your ride early
                                  in the morning, as you will be heading to
                                  Mandawa.
                                </span>
                              </li>
                              <li>
                                <i className="fa-regular fa-circle-check"></i>{" "}
                                <span>
                                  Mandawa is a small village at the beginning of
                                  the Thar desert.
                                </span>
                              </li>
                              <li>
                                <i className="fa-regular fa-circle-check"></i>{" "}
                                <span>
                                  After reaching, make sure you unwind from the
                                  long and hectic journey. You can also explore
                                  the local market and buy some souvenirs for
                                  your loved ones.
                                </span>
                              </li>
                              <li>
                                <i className="fa-regular fa-circle-check"></i>{" "}
                                <span>You will be staying overnight here.</span>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      </li>
                    ))}

                  {/* <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">2</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 2 - Mandawa - Jodhpur
                        </h4>
                        <p>
                          <small className="text-muted"> Mandawa</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <p className="mb-2 fw-bold">Ready to explore Jodhpur</p>
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              On the Second day of your Delhi to Jaipur bike
                              Trip, Wake up early in the morning and set out for
                              Jodhpur which is the entry of the Thar Desert.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              As you enter Jodhpur, you will notice that the
                              city is colored in a shade of blue. It is because
                              everybody has painted their houses in blue color.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Jodhpur Sightseeing is also included in this
                              Rajasthan bike Trip. Sightseeing in Jodhpur is
                              incomplete without visiting the great Mehrangarh
                              Fort, a former palace turned into a museum. It
                              consists of old paintings, furniture, armor,
                              weapons, and royal chairs.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              You will be staying overnight in Jodhpur.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">3</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 3 - Jodhpur - Jaisalmer
                        </h4>
                        <p>
                          <small className="text-muted">Jodhpur</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <p className="mb-2">Ready to explore Jodhpur</p>
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              After having breakfast you will begin your ride
                              from Jodhpur to reach Jaisalmer.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Upon reaching Jaisalmer, you will get to know why
                              it is popularly known as the Golden City. It is
                              because the buildings are made of yellow
                              sandstone.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              While entering Jaisalmer, you will notice the fort
                              on the top of the Hill. And guess what? It’s a
                              living fort!
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Check into the hotel and stay overnight there. And
                              this is how you will complete day 3 of the
                              Rajasthan bike trip.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">4</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 4 - Jaisalmer- Longewala - Jaisalmer
                        </h4>
                        <p>
                          <small className="text-muted">Jaisalmer</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <p className="mb-2 fw-bold">
                          Ready to explore Jaisalmer
                        </p>
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              On the fourth day of your Rajasthan bike trip, You
                              will be heading toward the western part of
                              Rajasthan which is very close to the Indo-Pak
                              border.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              It is a very beautiful location and a historic one
                              as it was the place where the war of 1971 also
                              known as the Battle of Longewala took place.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              You will be entering the core desert, and get a
                              surreal feeling of the desert, due to the Dunes
                              and Camels.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Head back to Jaisalmer and sign out for the day.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">5</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 5 - Jaisalmer - Udaipur
                        </h4>
                        <p>
                          <small className="text-muted">Jaisalmer</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <p className="mb-2 fw-bold">Ready to explore Udaipur</p>
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Prepare yourself for the long day ahead as you
                              will be covering almost 500 Kms cruising through
                              jungles and small hills.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Udaipur is famously known as the lake city, as it
                              has a lot of artificial and real lakes. Also, it
                              is famous for the regal residences present there.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              One of the famous lakes that are present there is
                              Pichola Lake which has 11 spectacular palaces with
                              beautiful paintings and interiors.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Head back and check into a hotel, and relax after
                              a hectic fifth day of the Rajasthan bike trip.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">6</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 6 - Udaipur - Pushkar
                        </h4>
                        <p>
                          <small className="text-muted">Udaipur</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <p className="mb-2 fw-bold">Ready to explore Udaipur</p>
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              On the Sixth Day of the bike trip to Rajasthan,
                              Wake up early in the morning and prepare to set
                              out for Pushkar.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Pushkar is a place that is of great importance for
                              Hindus as it is a religious spot.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              It also has a temple of Lord Brahma with a lake.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              So make sure you explore the place to its fullest.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>Stay overnight in a hotel or a lodge.</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">7</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 7 - Pushkar - Jaipur
                        </h4>
                        <p>
                          <small className="text-muted">Pushkar</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <p className="mb-2 fw-bold">Ready to explore Pushkar</p>
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              While returning you will be riding through the
                              capital city of Rajasthan also known as the Pink
                              city, Jaipur.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              The ride will be comparatively small (150 Km), but
                              you will get to witness the beautiful views.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              There are a lot of places that you can visit if
                              you visit Jaipur, like Hawa mahal, Birla Temple,
                              Amber Fort, and Nahargarh fort.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              You will be staying overnight in Jaipur.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted timeline-item">
                    <div className="timeline-badge warning">8</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4 className="timeline-title">
                          Day 8 - Jaipur - Delhi
                        </h4>
                        <p>
                          <small className="text-muted">Jaipur</small>
                        </p>
                      </div>
                      <div className="timeline-body">
                        <ul className="days-list">
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Wake up and have a hearty breakfast and prepare
                              for the last drive of this Rajasthan bike trip.
                            </span>
                          </li>
                          <li>
                            <i className="fa-regular fa-circle-check"></i>{" "}
                            <span>
                              Drive to Jaipur and bid farewell to the amazing
                              bike ride experience.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3 mb-4">
              <div className="side-bx p-3 mb-3">
                <a
                  href="javascript:void(0)"
                  className="btn red-btn py-2 px-4 w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#bookNow"
                >
                  Enquiry Now
                </a>
              </div>
              <div className="side-bx p-3 mb-3">
                <h4 className="fw-bold">Got a Question?</h4>
                <p>
                  Our Destination expert will be happy to help you resolve your
                  queries for this tour.
                </p>
                <div className="d-flex align-items-center">
                  <i className="fa-solid fa-phone-volume fs-4"></i>
                  <h5 className="mb-0 ms-2 fw-light">+91-9829324887</h5>
                </div>
              </div>

              <div className=" bg-light p-3 side-bx">
                <div className="h5 fw-bold">Why Choose Us?</div>
                <ul>
                  <li className="text-12 text-success-2 font-m fw-500">
                    <i className="icon-arrow-right text-12 mr-5"></i> All in one
                    platform
                  </li>
                  <li className="text-12 text-success-2 font-m fw-500">
                    <i className="icon-arrow-right text-12 mr-5"></i> Never
                    Settle for Average
                  </li>
                  <li className="text-12 text-success-2 font-m fw-500">
                    <i className="icon-arrow-right text-12 mr-5"></i> Truly
                    Unique Experiences
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-12 tour-crousel">
              <div className="title-header">
                <h2 className="title">Related Tours</h2>
              </div>
              <OwlCarousel options={options}>
                {bikeTourObj &&
                  bikeTourObj.length > 0 &&
                  bikeTourObj.map((vl, index) => {
                    return (
                      <div className="item">
                        <div className="tc-box">
                          <div className="tc-img">
                            <img
                              src={
                                "https://admin.uniqueindiaadventures.com/uploads/" +
                                vl?.Pag_Image
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="tc-content">
                            <h6>{vl?.Pag_URL}</h6>
                            <div className="d-flex justify-content-between">
                              <div>
                                <small className="fw-normal text-muted">
                                  {" "}
                                  Starts From
                                  <span className="fw-bold fs-6 text-dark ms-2">
                                    ₹19500
                                  </span>
                                </small>
                              </div>
                              <div>
                                <span className="font-m"> 4.6 </span>
                                <span className="font-m">
                                  <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#008009" }}
                                  ></i>
                                </span>
                                <span
                                  className="text-12"
                                  style={{ color: "#959595" }}
                                >
                                  {" "}
                                  (20){" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div> */}
        </div>
      </section>
    </Layout>
  );
};

export default TourDescription;
