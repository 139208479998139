import React, { useEffect } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getBlogCategory } from "../constants/reducers/action";

import { useNavigate } from "react-router-dom";
import { PATHS } from "../constant";
const BlogCategory = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { blogCategoryObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!blogCategoryObj) {
      dispatch(getBlogCategory());
    }
  }, [blogCategoryObj]);

  const _redirect = (obj) => {
    navigate(PATHS.BLOGS, { state: obj?.id });
  };
  return (
    <div class="col-lg-3">
      <div class="sidebar">
        {/* <div class="sidebar-item search-form">
          <h4 class="sidebar-title">Search</h4>
          <form action="" class="mt-3">
            <input type="text" />
            <button type="submit">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div> */}

        <div class="sidebar-item categories">
          <h3 class="sidebar-title">Categories</h3>
          <ul class="mt-3">
            {blogCategoryObj &&
              blogCategoryObj.map((vl, index) => (
                <li key={index} onClick={() => _redirect(vl)}>
                  <span>
                    {vl?.name} <span>({vl?.count})</span>
                  </span>
                </li>
              ))}
          </ul>
        </div>

        {/* <div class="sidebar-item tags">
          <h3 class="sidebar-title">Tags</h3>
          <ul class="mt-3">
            <li>
              <a href="#">Adventure India Trips</a>
            </li>
            <li>
              <a href="#">Beaches in India</a>
            </li>
            <li>
              <a href="#">Bikes Tour</a>
            </li>
            <li>
              <a href="#">Bike tours in Rajasthan</a>
            </li>
            <li>
              <a href="#">Discover India</a>
            </li>
            <li>
              <a href="#">Himalaya</a>
            </li>
            <li>
              <a href="#">Kerala</a>
            </li>
            <li>
              <a href="#">Incredible India</a>
            </li>
            <li>
              <a href="#">Indian Rides</a>
            </li>
            <li>
              <a href="#">Motorcycle tours</a>
            </li>
            <li>
              <a href="#">Indian Food</a>
            </li>
            <li>
              <a href="#">Motorcycle tours in Rajasthan</a>
            </li>
            <li>
              <a href="#">Motorcycle trip India</a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default BlogCategory;
