import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../constants/reducers/action";

const OurServices = () => {
  const dispatch = useDispatch();
  const { servicesObj } = useSelector((state) => state.collections);

  useEffect(() => {
    if (!servicesObj) {
      dispatch(getServices());
    }
  }, []);

  return (
    <section id="services" className="services pb-4 pos-rel">
      <div className="pattern-layer-01"></div>
      <div className="container pt-5">
        <div className="section-title-w">
          <span>Modern & Beautiful</span>
          <h2>Our Services</h2>
        </div>

        <div className="row justify-content-center pt-4">
          {servicesObj &&
            servicesObj.map((vl, key) => (
              <div key={key} className="col-md-6 col-lg-4 mb-5 pb-2">
                <div className="icon-box">
                  <i className={vl?.Pag_URL}></i>
                  <div className="services-content">
                    <h4>{vl?.Pag_Name}</h4>
                    <p>{vl?.Pag_ShortDesc}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default OurServices;
