export const ENDPOINT = "https://admin.uniqueindiaadventures.com/api/";

export const PATHS = {
  SITE: "/",
  ABOUTUS: "/about-unique-india-adventures",
  CONTACTUS: "/contact-unique-india-adventures",
  GALLERY: "/unique-india-adventures-gallery",
  BIKETOUR: "/bike-tour-with-unique-india-adventures",
  DOMESTICTOUR: "/domestic-tour",
  TOURDESTINATION: "/tourdestination",
  INTERNATIONALTOUR: "/international-tour",
  TOURDESCRIPTION: "/tour-description/:id",

  FAQ: "/faq-with-unique-india-adventures",
  TESTIMONIAL: "/testimonial",
  TERMSCONDITION: "/terms-and-conditions",
  BLOGS: "/unique-india-adventures-blogs",
  BLOGDETAILS: "/blog-details/:id",
};
