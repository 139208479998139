import React, { Component } from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";
function About() {
  return (
    <Layout>
      <Heading title={"About us"} />
      <section>
        <div className="container">
          <div className="row align-items-center mb-4">
            <div className="col-md-5">
              <div className="title-header">
                <span>Know more about us!</span>
                <h2 className="title">India's Best Travel Agency</h2>
              </div>
              <p>
                Mr. Abdul Said has transformed his passion for biking into a
                successful bike tour company over the past decade. His journey
                began as a driver for Tempo Travels, where he was responsible
                for transporting luggage for bike tours. This experience allowed
                him to gain valuable insights into the biking community and the
                logistics involved in organizing such tours. Recognizing the
                potential for growth, he saved diligently to purchase his own
                tempo travel vehicle, which enabled him to provide more
                personalized support during tours. As he transitioned into a
                supportive guide, he utilized his extensive knowledge of the
                routes and the needs of bikers to enhance the touring
                experience.
              </p>
              <p>
                Eventually, he decided to take the leap and establish his own
                independent bike tour company, allowing him to operate on his
                own terms. Today, Mr. Abdul Said proudly offers unforgettable
                biking adventures, drawing on his years of experience and
                dedication to provide exceptional service to his clients. His
                story exemplifies hard work, resilience, and the pursuit of
                passion, illustrating how a humble beginning can lead to
                remarkable achievements in the biking industry.
              </p>

              <a
                href="under-maintenance.html"
                className="btn red-btn py-3 px-4"
              >
                Booking Now
              </a>
            </div>

            <div className="col-md-7">
              <img
                src="assets/img/about-t-bg-2.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="tf-row">
                <div className="tf-icon">
                  <i className="fa-regular fa-compass"></i>
                </div>
                <div className="tf-content">
                  <h2>Our Mission</h2>
                  <p>
                    We are dedicated to providing exceptional travel experiences
                    that showcase the beauty, diversity, and rich heritage of
                    India. Our mission is to create unforgettable journeys and
                    make your travel dreams come true.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tf-row">
                <div className="tf-icon">
                  <i className="fa-solid fa-briefcase"></i>
                </div>
                <div className="tf-content">
                  <h2>Expertise and Experience</h2>
                  <p>
                    With years of experience in the industry, our team of travel
                    experts possesses extensive knowledge about India's top
                    destinations, cultural nuances, and hidden gems. We leverage
                    this expertise to curate unique itineraries and deliver
                    unparalleled service.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tf-row">
                <div className="tf-icon">
                  <i className="fa-solid fa-umbrella-beach"></i>
                </div>
                <div className="tf-content">
                  <h2>Customer Satisfaction</h2>
                  <p>
                    At the core of our business philosophy is a commitment to
                    customer satisfaction. We strive to exceed your expectations
                    by providing personalized attention, prompt communication,
                    and impeccable service throughout your journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="counter-block">
        <div className="container" id="counter">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="section-title-w">
                <span>Ride Through the Pulse of India</span>
                <h2>
                  Embark on a two-wheeled odyssey through
                  <br /> the vibrant tapestry of India's soul.
                </h2>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 mb-4">
                  <div className="bm-box">
                    <div className="counter">
                      <span className="counter-value" data-count="07">
                        0
                      </span>
                      <span>+</span>
                    </div>
                    <h4>Years Exp</h4>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="bm-box">
                    <div className="counter">
                      <span className="counter-value" data-count="2">
                        0
                      </span>
                      <span>k+</span>
                    </div>
                    <h4>Top Hosts</h4>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="bm-box">
                    <div className="counter">
                      <span className="counter-value" data-count="50">
                        0
                      </span>
                      <span>+</span>
                    </div>
                    <h4>Top Country</h4>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="bm-box">
                    <div className="counter">
                      <span className="counter-value" data-count="10">
                        0
                      </span>
                      <span>k+</span>
                    </div>
                    <h4>Client Avilable</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-choose">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title pb-4">
                <span>Happy Moments</span>
                <h2>Why Choose Unique India Adventures?</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className="col-md-4 mb-4">
                  <div className="wcu-icons">
                    <i className="fa-solid fa-earth-americas"></i>
                    <h5>Exceptional Customer Service</h5>
                    <p>
                      At Unique India Adventures, we prioritize your
                      satisfaction above all else. Our dedicated team provides
                      personalized assistance and prompt responses to your
                      queries, ensuring a seamless and enjoyable travel
                      experience.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="wcu-icons">
                    <i className="fa-solid fa-money-bill-wave"></i>
                    <h5>Extensive Local Knowledge</h5>
                    <p>
                      With our in-depth understanding of India's diverse
                      destinations, we curate unique experiences and tailor-made
                      itineraries that showcase the country's hidden gems and
                      cultural nuances.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="wcu-icons">
                    <i className="fa-solid fa-map-location-dot"></i>
                    <h5>Tailored Experiences</h5>
                    <p>
                      We believe that every traveler is unique, with specific
                      preferences and interests. Our customized bike touring
                      packages ensure a truly personalized and memorable journey
                      tailored to your individual travel style.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="wcu-icons">
                    <i className="fa-solid fa-calendar-days"></i>
                    <h5>Value for Money</h5>
                    <p>
                      We strive to offer excellent value for money without
                      compromising on the quality of our services. Our carefully
                      selected accommodations, transportation, and activities
                      provide a balance between comfort, authenticity, and
                      affordability.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="wcu-icons">
                    <i className="fa-solid fa-user-plus"></i>
                    <h5>Commitment to Responsible Tourism</h5>
                    <p>
                      We are committed to promoting responsible tourism
                      practices. Our sustainable and socially conscious approach
                      prioritizes the environment, cultural heritage, and local
                      communities, ensuring a positive impact on the
                      destinations we visit.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="wcu-icons">
                    <i className="fa-solid fa-cart-flatbed-suitcase"></i>
                    <h5>Responsible Travel</h5>
                    <p>
                      As a socially conscious company, we promote ethical travel
                      practices, respect for local cultures and environments,
                      and support for local communities. By choosing Unique
                      India Adventures, you contribute to responsible tourism
                      initiatives and help make a positive difference.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default About;
