import React from "react";

import BookYourTour from "./BookYourTour";
const WhatWeServe = () => {
  return (
    <section className="booking-order pt-3 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="section-title pb-2">
              <span>WHAT WE SERVE</span>
              <h2>Top Values For You!</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src="assets/img/feature-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                    <div className="lower-content">
                      <div className="icon-box">
                        <i className="fa-solid fa-plane-departure"></i>
                      </div>
                      <h4>Tour Packages</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src="assets/img/feature-2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                    <div className="lower-content">
                      <div className="icon-box">
                        <i className="fa-solid fa-car"></i>
                      </div>
                      <h4>Car On Rent</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 feature-block">
                <div className="feature-block-one">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src="assets/img/feature-3.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                    <div className="lower-content">
                      <div className="icon-box">
                        <i className="fa-solid fa-hotel"></i>
                      </div>
                      <h4>Hotel Bookings</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <BookYourTour />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeServe;
