import React, { useEffect } from "react";

import Layout from "./layout";
import { useForm, SubmitHandler } from "react-hook-form";
import Heading from "./layout/Heading";
import { submitEnquiry } from "../constants/reducers/action";
import { useDispatch, useSelector } from "react-redux";

const Contact = () => {
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { response } = useSelector((state) => state.collections);
  useEffect(() => {
    if (response) {
      reset();
    }
  }, [response]);
  const onSubmit = (data) => {
    dispatch(submitEnquiry(data));
  };
  return (
    <Layout>
      <Heading title={"Contact Us"} />

      <section className="booking-order contactPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="title-header mb-4">
                <span>Contact Us</span>
                <h2 className="title">
                  Ready to Travel Us for Better Adventure
                </h2>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="contact-icon-box">
                    <div className="icon">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="text">
                      <h4 className="title">Locations</h4>
                      <p>
                        <strong>Office:</strong> <br />
                        P.No. 229, Nehru Nagar, <br />
                        Delhi Road, Kunda Amer <br />
                        Rajasthan, India
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="contact-icon-box">
                    <div className="icon">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="text">
                      <h4 className="title">Email Us</h4>
                      <p>
                        <a href="mailto:support@gmail.com">
                          uniqueindiaadventures@gmail.com
                        </a>
                      </p>
                      <p>
                        <a href="mailto:support@gmail.com">
                          info@uniqueindiaadventures.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="contact-form-wrapper">
                <h5 className="title">
                  Need assistance or have any inquiries?
                </h5>
                <p>
                  Fill out the form below, and our dedicated team will get back
                  to you promptly to provide the support you need.
                </p>
                <form
                  className="contact-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form_group">
                    <input
                      type="text"
                      className="form_control"
                      placeholder="Full Name"
                      name="name"
                      {...register("Con_Name", { required: true })}
                    />
                    {errors.Con_Name && (
                      <span className="text-danger pt-0 mt-0">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="form_group mb-1">
                    <input
                      type="text"
                      className="form_control"
                      placeholder="Email Address"
                      name="email"
                      {...register("Con_Email", {
                        required: "This field is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    {errors.Con_Email && (
                      <span className="text-danger">
                        {errors.Con_Email.message}
                      </span>
                    )}
                  </div>
                  <div className="form_group">
                    <textarea
                      className="form_control"
                      placeholder="Write Message"
                      {...register("Con_Desc", { required: true })}
                    ></textarea>
                    {errors.Con_Desc && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="form_group">
                    <button type="submit" className="btn red-btn py-3 px-4">
                      Send message <i className="fa-solid fa-arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
