import React from "react";
import Layout from "./layout";
import Heading from "./layout/Heading";

const TourDestination = () => {
  return (
    <Layout>
      <Heading title={"Tour Destinations"} />
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/destinations/Agra1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>2 days</h4> */}
                    <h3>Agra</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="destination-item item">
                <a href="rajasthan-bike-tour.html">
                  <img
                    src="assets/img/destinations/Rajasthan.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>6-7 days</h4> */}
                    <h3>Rajasthan</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/destinations/Delhi.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>3-4 days</h4> */}
                    <h3>Delhi</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/destinations/Shimla.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>3-4 days</h4> */}
                    <h3>Shimla</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="destination-item item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/destinations/Varanasi.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>2-3 days</h4> */}
                    <h3>Varanasi</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="destination-item">
                <a href="under-maintenance-2.html">
                  <img
                    src="assets/img/destinations/goa.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>4-5 days</h4> */}
                    <h3>Goa</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="destination-item">
                <a href="under-maintenance.html">
                  <img
                    src="assets/img/destinations/Sikkim.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="info-overlay">
                    {/* <h4>4-5 days</h4> */}
                    <h3>Sikkim</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TourDestination;
