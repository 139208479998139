/** @format */

export const RESPONSE = "RESPONSE";
export const SLIDER = "SLIDER";
export const SERVICES = "SERVICES";
export const TESTIMONIALS = "TESTIMONIALS";
export const FAQ = "FAQ";
export const GALLERY = "GALLERY";
export const BIKETOUR = "BIKETOUR";
export const TOUR_DETAILS = "TOUR_DETAILS";
export const BLOGS = "BLOGS";
export const BLOG_DETAILS = "BLOG_DETAILS";
export const BLOG_CATEGORY = "BLOG_CATEGORY";
export const POPULAR_TOUR = "POPULAR_TOUR";
